import YWDPD_SimpleProduct from './simple-product';
import YWDPD_VariableProduct from './variable-product';

export default class YWDPD_GiftProducts {
	constructor( element, modal ) {
		this.elementDOM = element;
		this.modal = modal;
		this.itemsAllowed = this.elementDOM
			.find( '.ywdpd_single_rule_container' )
			.data( 'allowed_items' );
		this.maxUnits = this.elementDOM
			.find( '.ywdpd_single_rule_container' )
			.data( 'max_units' );
		this.confirmButton = this.elementDOM.find( '.ywdpd_btn_container' );
		this.products = this._initProducts();
		this.block_params = {
			message: null,
			overlayCSS: {
				background: '#fff',
				opacity: 0.6,
			},
			ignoreIfBlocked: true,
		};
		this.productInList = [];
		this.elementDOM
			.find( '.ywdpd_step2' )
			.on( 'select2:open', function () {
				jQuery( '.select2-container' ).addClass( 'ywcdd_select2' );
			} )
			.on( 'select2:closing', function () {
				jQuery( '.select2-container' ).removeClass( 'ywcdd_select2' );
			} );

		jQuery( document )
			.find( '.ywdpd_qty_arrows, .single_add_to_cart_button' )
			.on( 'touchstart mousedown', function ( e ) {
				// Prevent carousel swipe.

				e.stopImmediatePropagation();
			} );

		this.confirmButton.on( 'click', this.handleConfirmClick.bind( this ) );
	}

	_initProducts() {
		let productsObj = [];
		let products = this.elementDOM.find( '.ywdpd_popup_items li.product' );
		let globalList = this;
		products.each( function ( index ) {
			let productID = jQuery( this ).data( 'product_id' );
			let productType = jQuery( this ).data( 'product_type' );

			if ( productType !== 'variable' ) {
				productsObj[ productID ] = new YWDPD_SimpleProduct(
					jQuery( this ),
					globalList
				);
			} else {
				productsObj[ productID ] = new YWDPD_VariableProduct(
					jQuery( this ),
					globalList
				);
			}
		} );
		return productsObj;
	}

	_hashCode( s ) {
		let h = 0,
			i = s.length;
		while ( i > 0 ) {
			// eslint-disable-next-line no-bitwise
			h = ( ( h << 5 ) - h + s.charCodeAt( --i ) ) | 0;
		}
		return h;
	}
	getTotalAdded() {
		let qty_fields = this.elementDOM.find( 'li.product.item .ywdpd_qty' );
		let total = 0;
		qty_fields.each( function () {
			let qty = jQuery( this ).html();
			qty = '' === qty ? 0 : parseInt( qty );
			total = total + qty;
		} );
		return total;
	}

	get itemsAllowed() {
		return this._itemsAllowed;
	}

	set itemsAllowed( items ) {
		this._itemsAllowed = items;
	}

	disableAllAddToCart( disabled ) {
		if ( disabled ) {
			this.elementDOM
				.find( '.single_add_to_cart_button' )
				.addClass( 'ywdpd_button_disabled' );
		} else {
			this.elementDOM
				.find( '.single_add_to_cart_button' )
				.removeClass( 'ywdpd_button_disabled' );
		}
	}

	disableAllPlusButton( disabled ) {
		if ( disabled ) {
			this.elementDOM
				.find( '.ywdpd_qty_increase' )
				.addClass( 'ywdpd_button_disabled' );
		} else {
			this.elementDOM
				.find( '.ywdpd_qty_increase' )
				.removeClass( 'ywdpd_button_disabled' );
		}
	}

	disableAllMinusButton( disabled ) {
		if ( disabled ) {
			this.elementDOM
				.find( '.ywdpd_qty_decrease' )
				.addClass( 'ywdpd_button_disabled' );
		} else {
			this.elementDOM
				.find( '.ywdpd_qty_decrease' )
				.removeClass( 'ywdpd_button_disabled' );
		}
	}

	showConfirmButton( show ) {
		if ( show ) {
			this.confirmButton.show();
		} else {
			this.confirmButton.hide();
		}
	}

	maybeHideConfirmButton() {
		if ( this.getTotalAdded() === 0 ) {
			this.showConfirmButton( false );
		}
	}

	isProductInList( key ) {
		let productFound = this.productInList
			.filter( ( product ) => '' + product.key === '' + key )
			.shift();
		return productFound;
	}

	addProductInList( productID, qty = 1, variationID = 0, variations = {} ) {
		let key = this.generateKey( productID, qty, variationID, variations );
		if ( ! this.isProductInList( key ) ) {
			let obj = {
				key,
				product_id: productID,
				variation_id: variationID,
				variations,
				quantity: qty,
			};
			this.productInList.push( obj );
		}
		this.maybeDisableMoreAdd();
		return key;
	}

	generateKey( productID, qty = 1, variationID = 0, variations = {} ) {
		return this._hashCode(
			`${ productID }_${ variationID }_${ JSON.stringify( variations ) }`
		);
	}

	updateProductInList( key, qty ) {
		let newList = this.productInList.map( ( product ) => {
			let newProduct = { ...product };

			if ( '' + newProduct.key === key ) {
				newProduct.quantity = qty;
			}
			return newProduct;
		} );
		this.productInList = newList;

		this.maybeDisableMoreAdd();
	}

	maybeDisableMoreAdd() {
		if ( this.modal.mode === 'total_product' ) {
			let disabled = this.getTotalAdded() >= this.itemsAllowed;
			this.disableAllAddToCart( disabled );
			this.disableAllPlusButton( disabled );
		} else {
			let totalProductInList = this.productInList.length;
			let disabled = totalProductInList === this.itemsAllowed;
			this.disableAllAddToCart( disabled );
		}
	}

	canShowQtyFields() {
		return (
			( this.modal.mode === 'total_product' && this.itemsAllowed > 1 ) ||
			this.modal.mode === 'unit_product'
		);
	}

	removeProductInList( key ) {
		let newList = this.productInList.filter(
			( product ) => '' + product.key !== key
		);
		this.productInList = newList;
	}

	handleBackButton( event ) {
		event.preventDefault();
		this.backToStepOne();
	}

	backToStepOne() {
		let self = this;
		self.elementDOM.find( '.ywdpd_step2' ).fadeOut( 300, function () {
			self.elementDOM.find( '.ywdpd_step1' ).fadeIn( 300 );
			self.elementDOM.find( '.ywdpd_step2' ).html( '' );
		} );
	}

	goToStepTwo( template, caller ) {
		let self = this;
		this.elementDOM.find( '.ywdpd_step1' ).fadeOut( 300, function () {
			self.elementDOM.find( '.ywdpd_step2' ).html( template );
			self.elementDOM
				.find( '.ywdpd_step2 .variations_form' )
				.each( function () {
					jQuery( this ).wc_variation_form();
				} );
			self.elementDOM.find( '.ywdpd_step2' ).fadeIn( 300, function () {
				let backButton = self.elementDOM.find( '.ywdpd_back' );
				backButton.on( 'click', self.handleBackButton.bind( self ) );
				caller.elementDOM.trigger( 'ywdpd_step2_initialized' );
			} );
		} );
	}

	handleConfirmClick( event ) {
		event.preventDefault();
		let container = this.elementDOM.find( '.ywdpd_single_rule_container' );

		let product_added_with_qty_by_rule = {};

		product_added_with_qty_by_rule[ this.modal.ruleID ] =
			this.productInList;

		let ajaxAction,
			ajaxNonces = '';

		if ( container.hasClass( 'gift_products' ) ) {
			ajaxAction = ywdpd_popup_args.actions.add_gift_to_cart;
			ajaxNonces = ywdpd_popup_args.nonces.add_gift_to_cart;
		} else if ( container.hasClass( 'special_offer' ) ) {
			ajaxAction = ywdpd_popup_args.actions.add_special_to_cart;
			ajaxNonces = ywdpd_popup_args.nonces.add_special_to_cart;
		} else if ( container.hasClass( 'last_deals' ) ) {
			ajaxAction = ywdpd_popup_args.actions.add_last_deals;
			ajaxNonces = ywdpd_popup_args.nonces.add_last_deals;
		}

		if ( ajaxAction !== '' ) {
			let data = {
				rules_to_apply: product_added_with_qty_by_rule,
				action: ajaxAction,
				security: ajaxNonces,
			};

			let self = this;
			jQuery.ajax( {
				type: 'POST',
				url: ywdpd_popup_args.ajax_url,
				data,
				dataType: 'json',
				beforeSend() {
					self.elementDOM.block( self.block_params );
				},
				success( response ) {
					setTimeout( function () {
						if ( typeof response.fragments !== 'undefined' ) {
							jQuery( document.body ).trigger( 'added_to_cart', [
								response.fragments,
								response.cart_hash,
							] );
						}
					}, 1000 );
				},
				complete() {
					self.elementDOM.unblock();
					self.modal.close( true );
					jQuery( document.body ).trigger( 'gift_added_to_cart' );
				},
			} );
		}
	}
}

import QuantityInput from './quantity-input';

export default class YWDPD_AbstractProduct {
	constructor( element, globalList ) {
		/**
		 *  Properties get from dom
		 *
		 * @type {*|jQuery}
		 */
		this.elementDOM = element;
		this.globalList = globalList;
		this.productID = this.elementDOM.data( 'product_id' );
		this.variationID = 0;
		this.variations = {};
		this.type = this.elementDOM.data( 'product_type' );
		this.discountType = this.elementDOM.data( 'discount_type' );
		this.discountValue = this.elementDOM.data( 'discount_amount' );
		this.totalToAdd = this.elementDOM.data( 'total_to_add' );
		this.addToCartButton = this.elementDOM.find(
			'.single_add_to_cart_button'
		);
		this.badge = this.elementDOM.find( '.ywdpd_image_badge' );
		this.qtyInputFields = [];
		this._initQtyInput();

		// Events
		this.addToCartButton.on( 'click', this.handleAddToCart.bind( this ) );
	}

	_initQtyInput() {
		let fields = this.elementDOM.find( '.ywdpd_qty_field' ),
			min = this.elementDOM
				.find( '.ywdpd_qty_fields_container' )
				.data( 'min' ),
			max = this.elementDOM
				.find( '.ywdpd_qty_fields_container' )
				.data( 'max' ),
			self = this;
		fields.each( function () {
			let key = self.globalList.generateKey(
				self.productID,
				1,
				self.variationID,
				self.variations
			);
			self.qtyInputFields.push(
				new QuantityInput( jQuery( this ), key, min, max, self, false )
			);
		} );
	}

	setAddedToCart( value ) {
		this.addedToCart = value;
		this.elementDOM.trigger( 'ywdpd-added-to-cart' );
	}

	showQtyField( key ) {
		if ( 'variable' !== this.type ) {
			this.addToCartButton.hide();
		}
		let qty = false;
		jQuery.each( this.qtyInputFields, function ( index, qtyInputField ) {
			if ( '' + qtyInputField.getId() === '' + key ) {
				qty = qtyInputField;
				return false;
			}
		} );

		if ( qty ) {
			qty.toggleShowField( true );
			qty.plus.trigger( 'click' );
		}
	}

	removeProductFromCart( key ) {
		this.addToCartButton.show();
		this.hideBadge();
		this.globalList.removeProductInList( key );
		this.globalList.disableAllAddToCart( false );
		this.globalList.disableAllPlusButton( false );
		this.globalList.maybeHideConfirmButton();
	}

	hideBadge() {
		this.badge.hide();
	}

	handleAddToCart( event ) {
		event.preventDefault();
		this.badge.show();
	}
}

import YWDPD_MODAL from './ywdpd-modal';
// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'js-cookie';

export default class YWDPD_Modal_Manager {
	constructor() {
		this._initModals();
		this.showModal();

		jQuery( document ).on(
			'ywdpd_popup_ready',
			this.setModalAsVisited.bind( this )
		);
		jQuery( document ).on(
			'ywdpd_popup_closed',
			this.showModal.bind( this )
		);
		jQuery( document.body ).on(
			'updated_wc_div',
			this.getNewCartModals.bind( this )
		);
		jQuery( document.body ).on(
			'added_to_cart',
			this.getNewShopModals.bind( this )
		);
	}

	_initModals() {
		let modals = jQuery( document ).find( '.ywdpd_popup' );
		let modalsObj = [];

		modals.each( function () {
			modalsObj.push( new YWDPD_MODAL( jQuery( this ) ) );
		} );

		this.modalsObj = modalsObj.reverse();
	}

	setModalAsVisited( e, modal ) {
		let oldValues = Cookies.get( 'yith_ywdpd_modal_rule' );

		if ( typeof oldValues === 'undefined' ) {
			oldValues = [];
		} else {
			oldValues = JSON.parse( oldValues );
		}
		oldValues.push( modal.ruleID );

		Cookies.set( 'yith_ywdpd_modal_rule', JSON.stringify( oldValues ), {
			path: '/',
			expires: 1,
		} );
	}

	showModal() {
		if ( this.modalsObj.length ) {
			let currentModal = this.modalsObj.pop();

			setTimeout( function () {
				currentModal.show();
			}, 500 );
		}
	}

	getNewCartModals() {
		let data = {
			action: ywdpd_popup_args.actions.update_gift_popup,
			security: ywdpd_popup_args.nonces.update_gift_popup,
		};
		let self = this;
		jQuery.ajax( {
			type: 'POST',
			url: ywdpd_popup_args.ajax_url,
			data,
			dataType: 'json',

			success( response ) {
				if ( '' !== response.popup ) {
					jQuery( document.body ).append( jQuery( response.popup ) );
					self._initModals();
					self.showModal();
				}
			},
		} );
	}

	getNewShopModals() {
		let data = {
			action: ywdpd_popup_args.actions.show_popup_on_shop,
			security: ywdpd_popup_args.nonces.show_popup_on_shop,
		};
		let self = this;
		jQuery.ajax( {
			type: 'POST',
			url: ywdpd_popup_args.ajax_url,
			data,
			dataType: 'json',

			success( response ) {
				if ( '' !== response.popup ) {
					jQuery( document.body ).append( jQuery( response.popup ) );
					self._initModals();
					self.showModal();
				}
			},
			error( a, b, c ) {
				// eslint-disable-next-line no-console
				console.log( a, b, c );
			},
		} );
	}
}

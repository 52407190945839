export default class QuantityInput {
	/**
	 * The constructor
	 *
	 * @param {Object}                element
	 * @param {string}                id
	 * @param {string}                min
	 * @param {string}                max
	 * @param {YWDPD_AbstractProduct} product The product.
	 * @param {boolean}               show    Show or not the field.
	 */
	constructor( element, id, min = 0, max = '', product, show = true ) {
		this.elementDOM = element;
		this.product = product;
		this.showByDefault = show;
		this.min = min;
		this.hasMax = max !== '';
		this.max = this.hasMax ? parseInt( max ) : -1;
		this.plus = this.elementDOM.find( '.ywdpd_qty_increase' );
		this.minus = this.elementDOM.find( '.ywdpd_qty_decrease' );
		this.trash = this.elementDOM.find( '.ywdpd_qty_remove' );
		this.value = this.elementDOM.find( '.ywdpd_qty' );

		this.plus.on( 'click', this.handleClickPlus.bind( this ) );
		this.minus.on( 'click', this.handleClickMinus.bind( this ) );
		this.trash.on( 'click', this.handleClickTrash.bind( this ) );
		this.setId( id );
		this.handleShowMinus( false );
		this.handleShowPlus( true );
		this.handleShowTrash( true );
		this.toggleShowField( this.showByDefault );
	}

	handleClickPlus( event ) {
		event.preventDefault();
		event.stopImmediatePropagation();
		let currentValue = parseInt( this.value.html() );
		let newValue = currentValue + 1;
		if ( this.max === -1 || newValue <= this.max ) {
			this.value.html( newValue );
			this.handleShowPlus( true );
			if ( newValue > 1 ) {
				this.handleShowTrash( false );
				this.handleShowMinus( true );
			}
			this.product.globalList.updateProductInList(
				this.getId(),
				newValue
			);
		}
		if ( newValue === this.max ) {
			this.toggleDisablePlusButton( true );
			this.toggleDisableMinusButton( false );
		}
	}

	handleClickMinus( event ) {
		event.preventDefault();
		event.stopImmediatePropagation();
		let currentValue = parseInt( this.value.html() );
		let newValue = currentValue - 1;
		if ( newValue >= this.min ) {
			this.value.html( newValue );
			this.product.globalList.updateProductInList(
				this.getId(),
				newValue
			);
		}
		if ( newValue === this.min ) {
			this.toggleDisablePlusButton( false );
			this.toggleDisableMinusButton( false );
			this.handleShowMinus( false );
			this.handleShowTrash( true );
		}
	}

	toggleDisablePlusButton( disable ) {
		if ( disable ) {
			this.plus.addClass( 'ywdpd_button_disabled' );
		} else {
			this.plus.removeClass( 'ywdpd_button_disabled' );
		}
	}

	toggleDisableMinusButton( disable ) {
		if ( disable ) {
			this.minus.addClass( 'ywdpd_button_disabled' );
		} else {
			this.minus.removeClass( 'ywdpd_button_disabled' );
		}
	}

	handleClickTrash( event ) {
		event.preventDefault();
		let id = this.value.attr( 'id' );
		this.value.html( 0 );
		this.toggleShowField( false );
		this.product.removeProductFromCart( id );
	}

	toggleShowField( show ) {
		if ( show ) {
			this.elementDOM.show();
		} else {
			this.elementDOM.hide();
		}
	}

	handleShowPlus( show ) {
		if ( show ) {
			this.plus.addClass( 'visible' );
		} else {
			this.plus.removeClass( 'visible' );
		}
	}

	handleShowMinus( show ) {
		if ( show ) {
			this.minus.addClass( 'visible' );
		} else {
			this.minus.removeClass( 'visible' );
		}
	}

	handleShowTrash( show ) {
		if ( show ) {
			this.trash.addClass( 'visible' );
		} else {
			this.trash.removeClass( 'visible' );
		}
	}

	setId( id ) {
		this.value.attr( 'id', id );
	}

	getId() {
		return this.value.attr( 'id' );
	}
}

import YWDPD_AbstractProduct from './abstract-product';

export default class YWDPD_SimpleProduct extends YWDPD_AbstractProduct {
	handleAddToCart( event ) {
		let key = this.globalList.addProductInList( this.productID );
		if ( this.globalList.canShowQtyFields() ) {
			this.showQtyField( key );
			this.globalList.showConfirmButton( true );
		} else {
			this.globalList.confirmButton.click();
		}
		super.handleAddToCart( event );
	}
}

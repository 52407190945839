import YWDPD_GiftProducts from './gift-products';
export default class YWDPD_MODAL {
	constructor( popupContainer ) {
		this.elementDOM = popupContainer;
		this.noThanksButton = this.elementDOM.find( '.ywdpd_footer a' );
		this.closeButton = this.elementDOM.find( '.ywdpd_close' );
		this.countdown = '';
		this._initModal();
		this.noThanksButton.on( 'click', this.handleNoThanks.bind( this ) );
		this.closeButton.on( 'click', this.handleClose.bind( this ) );
		jQuery( window ).on( 'resize', this.handleCenterModal.bind( this ) );
	}

	_initModal() {
		let container = this.elementDOM.find( '.ywdpd_single_rule_container' );
		let wrapper = this.elementDOM.find( '.ywdpd_popup_wrapper' );
		this.ruleID = wrapper.data( 'rule_id' );
		this.mode = wrapper.data( 'mode' );
		this.countdown = container.data( 'countdown' );

		this._initSlider();
		this._initElements();

		if ( this.countdown !== '' ) {
			this._initCountdown();
		}
	}

	_initSlider() {
		let max_items = 3,
			sliders = this.elementDOM.find( '.ywdpd_popup_stage' );

		sliders.each( function () {
			let slider = jQuery( this ).find( '.owl-carousel' ),
				item = parseInt( slider.find( 'li' ).length ),
				autoWidth = true,
				margin = 30,
				center = false,
				nav = true,
				ul = jQuery( this ).find( 'ul.ywdpd_popup_items' );

			item = item > max_items ? max_items : item;

			if ( item > 1 ) {
				if ( item >= 3 ) {
					ul.css( { 'padding-left': '5px' } );
				} else {
					ul.css( { 'padding-left': '30px' } );
				}

				slider.owlCarousel( {
					loop: false,
					margin,
					center,
					autoWidth,
					nav,
					rtl: false,
					navText: [ '<span></span>', '<span></span>' ],
					responsiveClass: true,
					onInitialized( e ) {
						let count = e.item.count;

						if ( count > 1 ) {
							ul.find( '.owl-stage' ).css( { margin: '0 auto' } );
						}
					},
					responsive: {
						0: {
							items: 1,
							autoWidth: false,
							margin: 10,
						},
						721: {
							items: item,
						},
						1000: {
							items: item,
						},
					},
				} );
			} else {
				ul.addClass( 'ywdpd_one_item' );
			}
		} );
	}

	_initElements() {
		new YWDPD_GiftProducts(
			this.elementDOM.find( '.ywdpd_popup_content' ),
			this
		);
	}

	_center() {
		let popup_wrapper = this.elementDOM.find( '.ywdpd_popup_wrapper' );

		let rules = popup_wrapper.find( '.ywdpd_single_rule_container' ),
			max_width = 715,
			current_width = popup_wrapper.css( 'width' ),
			w,
			h = popup_wrapper.outerHeight(),
			W = this.elementDOM.width(),
			H = this.elementDOM.height(),
			screen_width = this.elementDOM.width();

		let top, left;
		if ( ! popup_wrapper.hasClass( 'full' ) ) {
			if ( screen_width > 720 ) {
				rules.each( function () {
					let li = jQuery( this ).find( 'ul li' ),
						amount_li = li.length;

					if ( amount_li >= 3 ) {
						current_width = max_width;
					} else {
						current_width = 438;
					}
				} );
			} else {
				current_width = current_width.replace( 'px', '' );
				current_width = parseFloat( current_width );
			}
			w = current_width;
			if ( jQuery( '#wpadminbar' ).length ) {
				h = h - jQuery( '#wpadminbar' ).height() - 10;
			}
			top = ( H - h ) / 2;
			left = ( W - w ) / 2;
		} else {
			top = 0;
			left = 0;
			popup_wrapper.find( '.ywdpd_popup_general_content' ).css( {
				height: 'calc( ' + h + 'px - 60px )',
			} );
		}
		popup_wrapper.css( {
			position: 'fixed',
			top: top + 'px', // '15%',
			left: left + 'px',
		} );
	}

	_initCountdown() {
		let container = this.elementDOM.find( '.ywdpd_single_rule_container' );
		if ( this.countdown !== '' ) {
			let config = this.countdown.split( ',' );
			let minutes = config[ 1 ] === 'minutes' ? config[ 0 ] : 0;
			let seconds = config[ 1 ] === 'seconds' ? config[ 0 ] : 0;

			minutes = minutes >= 10 ? minutes : '0' + minutes;
			seconds = seconds >= 10 ? seconds : '0' + seconds;

			let countdownDiv = jQuery(
				'<div class="ywdpd_countdown"><i class="ywdpd-icon-time-check"></i><div class="ywdpd_timer"></div>'
			);
			countdownDiv.insertAfter( container.find( '.ywdpd_rule_title' ) );
			this.elementDOM
				.find( '.ywdpd_timer' )
				.html( minutes + ':' + seconds );
			this.elementDOM.find( '.ywdpd_countdown' ).css( 'display', 'flex' );
		}
	}

	show() {
		let element = this;
		setTimeout( function () {
			element.elementDOM.fadeIn( 300 );
			element._center();
			element.startCountDown();
			jQuery( document ).trigger( 'ywdpd_popup_ready', [ element ] );
		}, 900 );
	}

	startCountDown() {
		if ( this.countdown !== '' ) {
			let config = this.countdown.split( ',' );
			let countDownMinutes =
				config[ 1 ] === 'seconds' ? 0 : parseInt( config[ 0 ] );
			let countDownSeconds =
				config[ 1 ] === 'seconds' ? parseInt( config[ 0 ] ) : 0;
			let countDownDate = false;
			let self = this;

			if ( 'minutes' === config[ 1 ] ) {
				countDownDate = new Date(
					new Date().getTime() + countDownMinutes * 60000
				);
			} else {
				countDownDate = new Date(
					new Date().getTime() + countDownSeconds * 1000
				);
			}

			let x = setInterval( function () {
				let now = new Date().getTime();
				// Find the distance between now and the countdown date
				let distance = countDownDate.getTime() - now;
				let minutes = Math.floor(
					( distance % ( 1000 * 60 * 60 ) ) / ( 1000 * 60 )
				);
				let seconds = Math.floor( ( distance % ( 1000 * 60 ) ) / 1000 );

				minutes = minutes >= 10 ? minutes : '0' + minutes;
				seconds = seconds >= 10 ? seconds : '0' + seconds;
				self.elementDOM
					.find( '.ywdpd_timer' )
					.html( minutes + ':' + seconds );
				if ( distance < 0 ) {
					clearInterval( x );
					self.close();
				}
			}, 1000 );
		}
	}

	close( with_changes ) {
		with_changes =
			typeof with_changes !== 'undefined' ? with_changes : false;
		this.elementDOM.fadeOut( 300 ).remove();
		if ( with_changes ) {
			if ( this.elementDOM.hasClass( 'cart' ) ) {
				jQuery( document ).trigger( 'wc_update_cart' );
			} else if ( this.elementDOM.hasClass( 'checkout' ) ) {
				jQuery( document.body ).trigger( 'update_checkout' );
			}
		}
		jQuery( document ).trigger( 'ywdpd_popup_closed' );
	}

	handleNoThanks( event ) {
		event.preventDefault();
		this.close();
	}

	handleClose( event ) {
		event.preventDefault();
		this.close();
	}

	handleCenterModal() {
		this._center();
	}
}

import YWDPD_AbstractProduct from './abstract-product';
import QuantityInput from './quantity-input';

export default class YWDPD_VariableProduct extends YWDPD_AbstractProduct {
	constructor( element, globalList ) {
		super( element, globalList );
		this.variationID = 0;
		this.elementDOM.on(
			'ywdpd_step2_initialized',
			this.initializeVariationForm.bind( this )
		);
	}

	handleAddToCart( event ) {
		event.preventDefault();
		let ruleType = this.addToCartButton.data( 'ywdpd_action' );
		let data = {
			product_id: this.productID,
			rule_id: this.globalList.modal.ruleID,
			rule_type: ruleType,
			action: ywdpd_popup_args.actions.show_second_step,
			security: ywdpd_popup_args.nonces.show_second_step,
		};

		let self = this;
		jQuery.ajax( {
			type: 'POST',
			url: ywdpd_popup_args.ajax_url,
			data,
			dataType: 'json',
			beforeSend() {
				self.elementDOM
					.parents( '.ywdpd_single_rule_container' )
					.block( self.globalList.block_params );
			},
			success( response ) {
				if ( response.template !== '' ) {
					// go_to_step2( response.template );
					self.globalList.goToStepTwo( response.template, self );
				}
			},
			complete() {
				self.elementDOM
					.parents( '.ywdpd_single_rule_container' )
					.unblock();
			},
		} );
	}

	hideBadge() {
		if (
			this.elementDOM.find(
				'.ywdpd_qty_fields_container .ywdpd_qty_field'
			).length === 0
		) {
			super.hideBadge();
		}
	}

	resetQtyInput( qtyInputDOM, qtyInput ) {
		qtyInputDOM.remove();
	}

	initializeVariationForm( event ) {
		event.stopImmediatePropagation();
		let variationForm = this.globalList.elementDOM.find(
			'.variations_form.cart'
		);
		let self = this;
		variationForm
			.on( 'found_variation', function ( e, variation ) {
				let form = jQuery( this );
				let data = {
					ywdp_check_rule_id: self.ruleID,
					product_id: self.productID,
					rule_type: self.globalList.elementDOM
						.find( 'input.ywdpd_rule_type' )
						.val(),
					type_discount: self.discountType,
					amount_discount: self.discountValue,
					tot_to_add: self.totalToAdd,
					action: ywdpd_popup_args.actions.check_variable,
					security: ywdpd_popup_args.nonces.check_variable,
				};

				jQuery.ajax( {
					type: 'POST',
					url: ywdpd_popup_args.ajax_url,
					data,
					dataType: 'json',
					beforeSend() {
						form.block( self.globalList.block_params );
					},
					success( response ) {
						if ( ! response.variation_found ) {
							self.variationID = variation.variation_id;
							if ( variation.is_in_stock ) {
								self.globalList.elementDOM
									.find( '.ywdpd_add_to_gift' )
									.removeClass( 'disabled' );
								self.globalList.elementDOM
									.find(
										'.ywdpdp_single_product p.stock.out-of-stock'
									)
									.remove();
							} else {
								// eslint-disable-next-line max-len
								self.globalList.elementDOM
									.find(
										'.ywdpdp_single_product .variations_form.cart'
									)
									.append( variation.availability_html );
								self.globalList.elementDOM
									.find( '.ywdpd_add_to_gift' )
									.addClass( 'disabled' );
							}
						} else {
							self.variationID = 0;
							self.variations = {};
							self.globalList.elementDOM
								.find( '.ywdpd_add_to_gift' )
								.addClass( 'disabled' );
						}
						self.globalList.elementDOM
							.find( '.ywdpd_step2 .price' )
							.html( response.price );
						self.globalList.elementDOM
							.find( '.ywdpd_step2 .price' )
							.show();
					},
					complete() {
						form.unblock();
					},
				} );
			} )
			.on( 'reset_data', function () {
				self.globalList.elementDOM
					.find( '.ywdpd_add_to_gift' )
					.addClass( 'disabled' );
				self.globalList.elementDOM
					.find( '.ywdpdp_single_product p.stock.out-of-stock' )
					.remove();
				self.globalList.elementDOM.find( '.ywdpd_step2 .price' ).hide();
			} );

		self.globalList.elementDOM
			.find( '.ywdpd_add_to_gift' )
			.on( 'click', self.handleSaveOptions.bind( self ) );
	}

	getVariations() {
		let button = this.globalList.elementDOM.find( '.ywdpd_add_to_gift' ),
			container = button.closest( '.ywdpd_single_product_right' ),
			variations = container.find( '[name^="attribute"]' ),
			var_items = {};

		variations.each( function () {
			let t = jQuery( this ),
				name = t.attr( 'name' );

			var_items[ name ] = t.val();
		} );

		return var_items;
	}

	getVariationHtml( variations ) {
		let button = this.globalList.elementDOM.find( '.ywdpd_add_to_gift' ),
			html = '',
			container = button.closest( '.ywdpd_single_product_right' );

		jQuery.each( variations, function ( name, value ) {
			let field = container.find( '[name ="' + name + '"]' ),
				label = container.find(
					'label[for="' + field.attr( 'id' ) + '"]'
				);

			html += '<div class="ywdpd_single_attribute">';
			html += "<span class='attribute_name'>" + label.html() + ':</span>';
			html += "<span class='attribute_value'>" + value + '</span>';
			html += '</div>';
		} );

		return html;
	}

	addNewQtyField( productContainer, key, variations ) {
		let qtyTemplate = wp.template( 'ywdpd-popup-quantity-field' );
		let variations_html = this.getVariationHtml( variations );
		let newQtyField = jQuery( qtyTemplate() );
		let divAttributes = jQuery(
			'<div class="ywdpd_attribute_fields"></div>'
		);
		let qtyContainer = productContainer.find(
			'.ywdpd_qty_fields_container'
		);

		divAttributes.html( variations_html );
		divAttributes.insertAfter( newQtyField.find( '.ywdpd_qty_label' ) );
		newQtyField.appendTo( qtyContainer ).show();
		this.qtyInputFields.push(
			new QuantityInput(
				newQtyField,
				key,
				qtyContainer.data( 'min' ),
				qtyContainer.data( 'max' ),
				this,
				true
			)
		);
		this.showQtyField( key );
	}

	handleSaveOptions( event ) {
		event.preventDefault();
		let productContainer = this.globalList.elementDOM.find(
				"li.item[data-product_id='" + this.productID + "']"
			),
			variations = this.getVariations(),
			key = this.globalList.addProductInList(
				this.productID,
				1,
				this.variationID,
				variations
			),
			qty_field = this.elementDOM.find( '#' + key + '.ywdpd_qty' );

		if ( ! qty_field.length ) {
			//Create new list
			this.addNewQtyField( productContainer, key, variations );
			this.globalList.showConfirmButton( true );
			this.badge.show();
		} else {
			qty_field.parent().find( '.ywdpd_qty_increase' ).click();
		}

		this.globalList.backToStepOne();
	}
}
